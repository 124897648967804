import { Link } from "react-router-dom";
import DayNight from "../components/DayNight";
import Img from "../components/Img";
import Button from "../components/ui/Button";
import zelle1 from "../images/projects/zelle/zelle1.png";
import zelle2 from "../images/projects/zelle/zelle2.png";
import zelle3 from "../images/projects/zelle/zelle3.png";

const ProjectZelle = () => {
  return (
    <DayNight>
      <div className="content-wrapper">
        <div className="project-page">
          <Link to="/" className="back-button">
            <Button targetID="home">
              <svg viewBox="0 0 24 24" width="24">
                <path
                  fill="currentColor"
                  d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                />
              </svg>
            </Button>
          </Link>
          <h2>Zelle by B&amp;T</h2>
          <p>
            <h3>Background</h3>
            As the UX Lead for Zelle by BB&amp;T, my goals were to clarify
            conceptual models (helping users to understand token-to-account
            relationships, etc), monitor usability through development with
            ongoing user testing schedules, and introduce accessibility
            standards to both the responsive web product and the native apps,
            using TalkBack and VoiceOver.
            <br />
            <br />I produced the interaction design, user experience design, and
            testing prototypes for this product.
          </p>
          <Img src={zelle1} />
          <p>
            <h3>Research</h3>Zelle was the subject of end-to-end usability
            testing and audits. In addition to this, I maintained a schedule of
            monthly in-person user testing, creating high-fidelity web and
            mobile prototypes in Axure to prioritize feature development and
            design decisions.
          </p>
          <Img src={zelle2} />
          <p>
            <h3>Process</h3>I led all user experience efforts as part of the
            Zelle scrum team, beginning with close collaboration with the
            product team during the road mapping stages, where I iterated on
            low-fidelity and paper prototypes until plans with technically
            solid, testable options were established. At this point, I created
            and maintained prototypes for new features and flows throughout the
            development process to test frequently with BB&amp;T banking
            clients. Product success was determined by metrics such as time on
            task, reduced volume of support requests, and users’ ability to
            quickly interpret key information in the interface.
          </p>
          <Img src={zelle3} />
        </div>
      </div>
    </DayNight>
  );
};

export default ProjectZelle;
