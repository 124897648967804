import { Link } from "react-router-dom";
import DayNight from "../components/DayNight";
import Button from "../components/ui/Button";
import Img from "../components/Img";
import HorizontalGroup from "../components/layout/HorizontalGroup";
import dynamo1 from "../images/projects/dynamo/dynamo1.png";
import dynamo2 from "../images/projects/dynamo/dynamo2.png";
import dynamo3 from "../images/projects/dynamo/dynamo3.png";
import dynamo4 from "../images/projects/dynamo/dynamo4.png";
import dynamo5 from "../images/projects/dynamo/dynamo5.png";
import dynamo6 from "../images/projects/dynamo/dynamo6.png";

const ProjectAutodesk = () => {
  return (
    <DayNight>
      <div className="content-wrapper">
        <div className="project-page">
          <Link to="/" className="back-button">
            <Button targetID="home">
              <svg viewBox="0 0 24 24" width="24">
                <path
                  fill="currentColor"
                  d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                />
              </svg>
            </Button>
          </Link>
          <h2>Autodesk Dynamo</h2>
          <p>
            <h3>Introduction</h3>Dynamo is an open-source visual scripting tool
            in the Autodesk ecosystem. In addition to Dynamo Sandbox, a
            standalone desktop product, Dynamo also offers scripting
            environments in other “host” products across the Autodesk portfolio
            of architecture, engineering, and product design tools, such as
            Revit, Civil3D, and Alias. In Dynamo, users construct visual logic
            processes to automate time-consuming tasks or to expand the
            functionality of a host product -- this could result in generative
            design studies to find ideal design solutions quickly, automatically
            making updates across a large Revit project, or a multitude of
            actions in between.
          </p>
          <p>
            <h3>Project Background</h3>In January 2020, I joined the Dynamo team
            to lead the redesign efforts. Initial goals were formed based on
            analytics and the vocal community of users and open-source
            contributors. Learnability, new user onboarding, and a cohesive,
            modern interface were important objectives -- as a Python-based
            scripting environment, Dynamo had developed a reputation of being
            difficult to understand, leaving less technical users hesitant to
            explore the product.
          </p>
          <Img
            src={dynamo1}
            alt="Dynamo"
            caption="Dynamo before the redesign project"
          />
          <p>
            <h3>Usability</h3>To kick off the redesign project, I recruited for,
            planned, and led one-on-one user interviews with customers of novice
            experience levels to identify new user experiences and high priority
            usability concerns. Trends from this research led to the design of
            Node Autocomplete, a feature to help new users more quickly learn to
            create successful logic flows, and to help experienced users
            streamline their processes with machine learning prompts. During
            research, Autocomplete earned a satisfaction and value score of 4.3
            out of 5, and after release, Autocomplete received enthusiastic
            responses from both beginner and experienced users.
          </p>
          <Img src={dynamo2} />
          <p>
            Along with specific features like Autocomplete, the first priority
            design tasks were usability improvements: meeting accessibile
            contrast requirements, adding clear, localization-friendly labels
            and titles, and reassessing node design patterns for clarity among
            novice users. During this phase of the project, repeated prototype
            usability tests with global users guided the final design patterns
            for Dynamo nodes.
          </p>
          <Img
            src={dynamo3}
            caption="Research participant, November 2020"
            // captionSide="right"
            quote="Beginners will find the new node pattern more intuitive; icons and
					node names makes it easy."
          />
          <p>
            <h3>Learnability</h3>The Dynamo team collaborated with a visual
            designer and content experience design to create new learning
            content. After multiple rounds of testing and refinement, this led
            to pattern playbook for contextual guides which is now used by a
            variety of teams at Autodesk.
          </p>
          <Img
            src={dynamo4}
            caption="Research participant, June 2021"
            // captionSide="right"
            quote="3 years ago, there wasn't any guide — it was hard to get into Dynamo.
					Here, you're being helped with baby steps into the program."
          />
          <p>
            <h3>Results</h3>After Global Launch 2022, metrics show increasing
            user numbers and interaction with new features and learning
            material. It’s also been incredibly satisfying to see the
            enthusiastic feedback across the web:
          </p>
          <HorizontalGroup>
            <Img src={dynamo5} />
            <Img src={dynamo6} />
          </HorizontalGroup>
        </div>
      </div>
    </DayNight>
  );
};

export default ProjectAutodesk;
