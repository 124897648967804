import { useState } from "react";
import "./App.scss";
import ContactForm from "./components/ContactForm";
import DayNight from "./components/DayNight";
import ProjectContent from "./components/ProjectContent";
import ProjectList from "./components/ProjectList";
import Button from "./components/ui/Button";
const App = () => {
	const [currentPage, setCurrentPage] = useState({
		type: "home",
		contentID: null,
	});
	const [contactFormData, setContactFormData] = useState({
		name: null,
		email: null,
		message: null,
	});
	const handleSetCurrentPage = ({ type, contentID }) => {
		setCurrentPage({ type: type, contentID: contentID });
	};

	const updateContactFormData = (key, value) => {
		const formData = contactFormData;
		formData[key] = value;
		setContactFormData({ ...formData });
	};

	return (
		<div>
			<ContactForm hidden formData={{ ...contactFormData }} />
			<DayNight setContactFormData={updateContactFormData}>
				{currentPage.type === "home" && (
					<div className="content-wrapper">
						<h1>
							<div className="header">Elizabeth Yount</div>
							<div className="subheader">Experience Designer</div>
						</h1>
						<ProjectList setCurrentPage={handleSetCurrentPage} />
						<ContactForm formData={{ ...contactFormData }} />
					</div>
				)}
				{currentPage.type === "project" && (
					<div className="content-wrapper">
						<Button
							onClick={() => setCurrentPage({ type: "home", contentID: null })}
							targetID="home-button"
						>
							Home
						</Button>
						<ProjectContent contentID={currentPage.contentID} />
					</div>
				)}
			</DayNight>
		</div>
	);
};

export default App;
