import classNames from "classnames";
import PropTypes from "prop-types";
import "./_styles/Button.scss";

const Button = (props) => {
	const { children, onClick, targetID, type, disabled, className } = props;
	return (
		<button
			className={classNames(["button", className, { disabled: disabled }])}
			onClick={() => onClick()}
			data-targetid={targetID}
			type={type}
			disabled={disabled}
		>
			{children}
		</button>
	);
};

Button.propTypes = {
	type: PropTypes.oneOf(["submit", "button"]),
	targetID: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.node,
};

export default Button;
