import { Link } from "react-router-dom";
import DayNight from "../components/DayNight";
import Button from "../components/ui/Button";
import Img from "../components/Img";
import HorizontalGroup from "../components/layout/HorizontalGroup";
import rac1 from "../images/projects/RAC/rac1.png";
import rac2 from "../images/projects/RAC/rac2.jpg";
import rac3 from "../images/projects/RAC/rac3.png";
import rac5 from "../images/projects/RAC/rac5.jpg";

const ProjectRegional = () => {
	return (
		<DayNight>
			<div className="content-wrapper">
				<div className="project-page">
					<Link to="/" className="back-button">
						<Button targetID="home">
							<svg viewBox="0 0 24 24" width="24">
								<path
									fill="currentColor"
									d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
								/>
							</svg>
						</Button>
					</Link>
					<h2>Regional Acceptance</h2>
					<p>
						<h3>Background</h3>
						RAC Auto was an all-new native iOS and Android app for Regional
						Acceptance auto loan holders. As UX lead, I was involved from the
						beginning of the project, handling interaction and UX design and
						documentation for our technical teams, high-fidelity prototyping,
						and user research.
					</p>

					<Img src={rac1} />

					<p>
						<h3>Research</h3>I synthesized initial data about our client base
						(device type, type of loan held, socio- economic factors, etc) to
						inform early designs. Early on in the project, I scheduled recurring
						biweekly usability testing sessions to monitor intuitiveness and
						accessibility of new features. After design direction was more
						defined, RAC Auto underwent a full week dedicated study with test
						participants recruited to closely match our user demographic.
					</p>

					<Img src={rac2} />

					<p>
						<h3>Process</h3> In the initial phase of design, I created
						low-fidelity wireframes and sketches to verify technical feasibility
						and direction with the product team. After this stage, I created and
						maintained a high-fidelity Axure prototype for user testing efforts
						throughout the development lifecycle. During research planning for
						the on-site weeklong study, I developed screener criteria to find
						participants that most closely represented RAC users.
					</p>

					<HorizontalGroup className="rac-image-group">
						<Img src={rac3} />
						<Img src={rac5} />
					</HorizontalGroup>
				</div>
			</div>
		</DayNight>
	);
};

export default ProjectRegional;
