import classNames from "classnames";
import "./_styles/HorizontalGroup.scss";
const HorizontalGroup = (props) => {
	const { children, className } = props;
	return (
		<div className={classNames(["horizontal-group", className])}>
			{children.map((x) => {
				return x;
			})}
		</div>
	);
};

export default HorizontalGroup;
