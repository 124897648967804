import { useState } from "react";
import PropTypes from "prop-types";
import Button from "./ui/Button";
import Input from "./ui/Input";
import TextArea from "./ui/TextArea";
import { useForm } from "@formspree/react";
import "./_styles/contactForm.scss";

const ContactForm = props => {
  const { hidden, formData } = props;
  const [state, handleSubmit] = useForm("xrgdeqpq");
  const [isNameValid, setIsNameValid] = useState(true);

  const simulateSubmitButtonClick = () => {
    document.getElementById("formSubmitButton").click();
  };

  if (state.succeeded) {
    return <div>Thank you for inquiring! We will be in touch soon!</div>;
  }

  const dayNightContactForm = (
    <>
      <hr />
      <form className="contact-form" onSubmit={handleSubmit}>
        <h2 className="contact-form-title">
          Let's Work <br />
          Together
        </h2>
        <div className="contact-form-body">
          <div className="form-group">
            <Input
              label="Name*"
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter name"
              targetID="contact-form-name"
            />
          </div>
          <div className="form-group">
            <Input
              label="Email*"
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter email"
              targetID="contact-form-email"
            />
          </div>
          <div className="form-group">
            <TextArea
              label="Message*"
              className="form-control"
              id="message"
              rows="3"
              placeholder="Enter message"
              targetID="contact-form-message"
            />
          </div>
          <Button
            targetID="contact_submit_btn"
            onClick={() => simulateSubmitButtonClick()}
            disabled={
              !(!!formData.name && !!formData.email && !!formData.message)
            }
          >
            Submit
          </Button>
          {!(!!formData.name && !!formData.email && !!formData.message) ? (
            <p>* All fields are required</p>
          ) : (
            <p>Ready to submit</p>
          )}
        </div>
      </form>
    </>
  );

  const validateForm = e => {
    console.log("!!formData.name: ", !!formData.name);
    isNameValid = !!formData.name;
    setIsNameValid(!!formData.name);

    !(!!formData.name && !!formData.email && !!formData.message) &&
      e.preventDefault();
  };

  const hiddenContactForm = (
    <form
      id="fs-frm"
      name="simple-contact-form"
      acceptCharset="utf-8"
      action="https://formspree.io/f/xrgdeqpq"
      onSubmit={e => validateForm(e)}
      method="post"
    >
      <fieldset id="fs-frm-inputs">
        <label htmlFor="full-name">Full Name</label>
        <input
          type="text"
          name="name"
          id="full-name"
          placeholder="First and Last"
          required=""
          defaultValue={formData?.name}
        />
        <label htmlFor="email-address">Email Address</label>
        <input
          type="email"
          name="_replyto"
          id="email-address"
          placeholder="email@domain.tld"
          required=""
          defaultValue={formData?.email}
        />
        <label htmlFor="message">Message</label>
        <textarea
          rows="5"
          name="message"
          id="message"
          placeholder="Aenean lacinia bibendum nulla sed consectetur. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec ullamcorper nulla non metus auctor fringilla nullam quis risus."
          required=""
          defaultValue={formData?.message}
        />
        <input
          type="hidden"
          name="_subject"
          id="email-subject"
          value="Contact Form Submission"
        />
      </fieldset>
      <input type="submit" value="Submit" id="formSubmitButton" />
    </form>
  );

  if (hidden) {
    return hiddenContactForm;
  } else {
    return dayNightContactForm;
  }
};

ContactForm.propTypes = {
  hidden: PropTypes.bool
};

ContactForm.defaultProps = {
  hidden: false
};

export default ContactForm;
