import classNames from "classnames";
import "./_styles/Input.scss";

const Input = (props) => {
	const { label, targetID, value, id, placeholder, type, hidden, name } = props;
	return (
		<div
			className={classNames(["input-group", hidden && "input-group-hidden"])}
		>
			<label htmlFor={id}>{label}</label>
			<div
				contentEditable="true"
				id={id}
				type={type}
				className={classNames(["input"])}
				data-targetid={targetID}
				value={value}
				placeholder={placeholder}
				name={name}
			/>
		</div>
	);
};

export default Input;
