import Button from "./ui/Button";
import projects from "../projects.json";
import "./_styles/ProjectList.scss";
import zellePreview from "../images/projects/zelle/zelle_preview.png";
import racPreview from "../images/projects/RAC/rac_preview.png";
import dynamoPreview from "../images/projects/dynamo/dynamo_preview.png";
import { Link } from "react-router-dom";

const getImage = (id) => {
	console.log("id: ", id);
	switch (id) {
		case "zelle":
			return zellePreview;
		case "rac":
			return racPreview;
		case "autodesk":
			return dynamoPreview;
	}
};

const ProjectListItem = (props) => {
	const { title, description, setCurrentPage, contentID } = props;
	return (
		<li>
			<img
				src={getImage(contentID)}
				alt={`Preview for ${title}`}
				className="project-preview-image"
			/>
			<div className="project-list-item-content">
				<h3 className="project-name">{title}</h3>
				<p>{description}</p>
				<Link to={`/${contentID}`}>
					<Button
						onClick={() =>
							setCurrentPage({ type: "project", contentID: contentID })
						}
						targetID={`project-button-${contentID}`}
					>
						View {contentID}
					</Button>
				</Link>
			</div>
		</li>
	);
};

const ProjectList = (props) => {
	const { setCurrentPage } = props;
	return (
		<ul className="project-list">
			{projects.map((project, index) => {
				return (
					<ProjectListItem
						key={index}
						{...project}
						setCurrentPage={setCurrentPage}
					/>
				);
			})}
		</ul>
	);
};

export default ProjectList;
