import ProjectAutodesk from "../projects/ProjectAutodesk";
import ProjectRegional from "../projects/ProjectRegional";
import ProjectZelle from "../projects/ProjectZelle";
import "./_styles/ProjectPage.scss";

const contentSwitcher = (props) => {
	const { contentID } = props;
	switch (contentID) {
		case "autodesk":
			return <ProjectAutodesk />;
		case "rac":
			return <ProjectRegional />;
		case "zelle":
			return <ProjectZelle />;
		default:
			return <h2>No Content</h2>;
	}
};

const ProjectContent = (contentID) => {
	return <div className="project-page">{contentSwitcher(contentID)}</div>;
};

export default ProjectContent;
