import { BrowserRouter, Routes, Route } from "react-router-dom";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import ProjectZelle from "./projects/ProjectZelle";
import ProjectRegional from "./projects/ProjectRegional";
import ProjectAutodesk from "./projects/ProjectAutodesk";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <StrictMode>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="zelle" element={<ProjectZelle />} />
        <Route path="rac" element={<ProjectRegional />} />
        <Route path="autodesk" element={<ProjectAutodesk />} />
      </Routes>
    </StrictMode>
  </BrowserRouter>
);
