import classNames from "classnames";
import "./_styles/TextArea.scss";

const TextArea = (props) => {
	const { label, targetID, value, id, placeholder, rows } = props;
	return (
		<div className="input-group">
			<label htmlFor={id}>{label}</label>
			<div
				contentEditable="true"
				id={id}
				className={classNames(["textarea"])}
				data-targetid={targetID}
				value={value}
				placeholder={placeholder}
				style={{ height: rows * 1.25 + "em" }}
			/>
		</div>
	);
};

export default TextArea;
