import PropTypes from "prop-types";
import classNames from "classnames";
import "./_styles/Img.scss";

const Img = (props) => {
	const { src, alt, caption, quote, captionSide } = props;
	return (
		<figure className={classNames("img", "img-position-" + captionSide)}>
			<img src={src} alt={alt} />
			<div className="caption-group">
				{quote && <blockquote>{quote}</blockquote>}
				{caption && <figcaption>{caption}</figcaption>}
			</div>
		</figure>
	);
};

Img.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	caption: PropTypes.string,
	quote: PropTypes.string,
	captionSide: PropTypes.oneOf(["right", "bottom"]),
};

Img.defaultProps = {
	captionSide: "bottom",
};

export default Img;
