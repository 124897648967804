import { useState, useCallback } from "react";
import "./_styles/dayNight.scss";
const DayNight = props => {
  const { children, setContactFormData } = props;
  const [clipPath, setClipPath] = useState("circle(100px at 0 0)");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hoveredID, setHoveredID] = useState("circle(100px at 0 0)");
  const [caretVisible, setCaretVisible] = useState(false);
  const [caretPosition, setCaretPosition] = useState({
    left: "0px",
    top: "0px"
  });

  const handleMouseMove = useCallback(
    e => {
      const { clientX, clientY } = e;
      setClipPath(
        "circle(100px at " +
          clientX +
          "px " +
          (clientY + scrollPosition) +
          "px)"
      );

      // Get hovered item's data-targetid
      const hoveredObjectTargetID = document
        ?.elementsFromPoint(clientX, clientY)[0]
        ?.getAttribute("data-targetid");

      // Set hoveredID if targetID has changed
      hoveredID !== hoveredObjectTargetID &&
        setHoveredID(hoveredObjectTargetID);

      // Add "hover" class to hovered items
      document
        .querySelectorAll(`[data-targetid="${hoveredObjectTargetID}"]`)
        .forEach(hoveredObject => {
          hoveredObject.classList.add("hover");
        });

      // Remove "hover" class from all other items
      document
        .querySelectorAll(
          `.hover:not([data-targetid="${hoveredObjectTargetID}"])`
        )
        .forEach(hoveredObject => hoveredObject.classList.remove("hover"));
    },
    [hoveredID, scrollPosition]
  );

  const handleMouseDown = useCallback(
    () => {
      document
        .querySelectorAll(`[data-targetid="${hoveredID}"]`)
        .forEach(hoveredObject => {
          hoveredObject.classList.add("mousedown");
        });
      const targetID = document
        .querySelectorAll(".focus")[1]
        ?.getAttribute("data-targetid");
      const nightInput = document.querySelectorAll(
        `[data-targetid="${targetID}"]`
      )[0];
      const dayInput = document.querySelectorAll(
        `[data-targetid="${targetID}"]`
      )[1];
      if (nightInput) {
        nightInput.innerHTML = dayInput.innerHTML;
      }
      document
        .querySelectorAll(`.focus:not([data-targetid="${hoveredID}"])`)
        .forEach(hoveredObject => hoveredObject.classList.remove("focus"));
    },
    [hoveredID]
  );

  const setFocusClasses = useCallback(() => {
    document.querySelectorAll(`.focus`).forEach(focusedInput => {
      focusedInput.classList.remove("focus");
    });
    const focusedTargetID = document.activeElement.getAttribute(
      "data-targetid"
    );
    document
      .querySelectorAll(`[data-targetid="${focusedTargetID}"]`)
      .forEach(targets => {
        targets.classList.add("focus");
      });
  }, []);

  const handleMouseUp = useCallback(
    () => {
      setFocusClasses();
      document
        .querySelectorAll(`[data-targetid="${hoveredID}"]`)
        .forEach(hoveredObject => {
          hoveredObject.classList.remove("mousedown");
        });
    },
    [hoveredID, setFocusClasses]
  );

  const decodeHtml = useCallback(html => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }, []);

  const handleTextInputChange = useCallback(
    () => {
      const focusedElements = document.querySelectorAll(".focus");
      const sourceValue = decodeHtml(focusedElements[1].innerHTML);
      focusedElements[0].innerHTML = sourceValue;

      // TODO: This is dumb, fix it later
      focusedElements[1].id === "name" &&
        setContactFormData("name", sourceValue);
      focusedElements[1].id === "email" &&
        setContactFormData("email", sourceValue);
      focusedElements[1].id === "message" &&
        setContactFormData("message", sourceValue);
    },
    [decodeHtml, setContactFormData]
  );

  const syncTextSelection = useCallback(
    () => {
      const selection = window.getSelection();

      const targetID = document
        .querySelectorAll(".focus")[1]
        ?.getAttribute("data-targetid");

      const nightInput = document.querySelectorAll(
        `[data-targetid="${targetID}"]`
      )[0];

      const nightInputText = decodeHtml(
        document.querySelectorAll(`[data-targetid="${targetID}"]`)[1]?.innerHTML
      );

      const range = [selection.anchorOffset, selection.focusOffset];
      const start = Math.min(...range);
      const end = Math.max(...range);

      if (nightInput) {
        nightInput.innerHTML =
          nightInputText.substring(0, start) +
          "<span class='selected-text'>" +
          nightInputText.substring(start, end) +
          "</span>" +
          nightInputText.substring(end);
      }
    },
    [decodeHtml]
  );

  const getCaretPixelPos = useCallback(
    targetInput => {
      setCaretVisible(!!targetInput);
      var rect = targetInput?.getBoundingClientRect();

      let offsetx = 0,
        offsety = scrollPosition,
        nodeLeft = 0,
        nodeTop = 0,
        pos = { left: 0, top: 0 },
        range;

      if (window.getSelection().anchorNode) {
        var sel = window.getSelection();
        range = sel.getRangeAt(0).cloneRange();
        try {
          range.setStart(
            range.startContainer,
            range.startOffset > 0 ? range.startOffset - 1 : 0
          );
        } catch (e) {
          console.error(e);
        }
        rect = range.getBoundingClientRect();
        if (range.endOffset === 0 || range.toString() === "") {
          // first char of line
          if (range.startContainer === targetInput) {
            // empty div
            if (range.endOffset === 0) {
              // Not sure why I had to grab this again here, but it works now
              const currentElementRect = document.activeElement.getBoundingClientRect();
              document.activeElement.innerHTML = "";
              pos.top = currentElementRect.top + 2 + offsety + "px";
              pos.left = currentElementRect.left + 6 + "px";
            } else {
              // firefox need this
              var range2 = range.cloneRange();
              range2.setStart(range2.startContainer, 0);
              var rect2 = range2.getBoundingClientRect();
              pos.left = rect2.left + offsetx - nodeLeft + "px";
              pos.top = rect2.top + rect2.height + offsety - nodeTop + "px";
            }
          } else {
            if (document.activeElement.innerHTML.length === 0) {
              pos.top = rect.top + 2 + "px";
              pos.left = rect.left + 6 + "px";
            } else {
              pos.top = rect.top + offsety + "px";
              pos.left = rect.left + "px";
            }
          }
        } else {
          setCaretVisible(
            !window.getSelection().toString().length && targetInput
          );
          pos.left = rect.left + rect.width + offsetx - nodeLeft + "px";
          pos.top = rect.top + offsety - nodeTop + "px";
        }
      }
      setCaretPosition(pos);
    },
    [scrollPosition]
  );

  const nightInputs = document.querySelectorAll(".night .input");
  const nightButtons = document.querySelectorAll(".night .button");
  const nightTextareas = document.querySelectorAll(".night .textarea");

  // combine day inputs and buttons
  const nightInputsAndButtons = [
    ...nightInputs,
    ...nightButtons,
    ...nightTextareas
  ];
  nightInputsAndButtons.forEach(input => {
    input.setAttribute("tabindex", -1);
  });

  return (
    <div
      className="background"
      onMouseMove={e => {
        handleMouseMove(e);
        syncTextSelection();
      }}
      onMouseDown={() => {
        handleMouseDown();
      }}
      onMouseUp={() => {
        handleMouseUp();
        getCaretPixelPos(document.querySelectorAll(".focus")[1]);
      }}
      onKeyUp={e => {
        if (e.key.includes("Tab")) {
          setFocusClasses();
        } else {
          handleTextInputChange();
        }
        getCaretPixelPos(document.querySelectorAll(".focus")[1]);
      }}
      onScroll={() => {
        const scrollPos = document
          .getElementById("background")
          .getBoundingClientRect().top;
        setScrollPosition(Math.abs(scrollPos));
      }}
    >
      <div className="night">
        {caretVisible && (
          <div
            className="caret"
            style={{ left: caretPosition.left, top: caretPosition.top }}
          />
        )}
        {children}
      </div>
      <div className="day" id="background" style={{ clipPath }}>
        {children}
      </div>
    </div>
  );
};

export default DayNight;
